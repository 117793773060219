import { defineStore } from 'pinia';
import { reactive } from 'vue';

enum ePermissions {
    View = 3,
    Add = 1,
    Edit = 2,
    Delete = 4
}

export interface iUserData {
    id: number // 使用者id
    account: string // 使用者帳號
    role: {
        id: number // roles_id
        name: string // roles_name
        image_type: number[] // image_type 廔管頁面要顯示哪幾個
        pagePermission: {
            id: number
            name: string
            icon: string
            href: string
            permissions: ePermissions[]
        }[]
    }
}

// 宣告、並匯出user的State
export interface iUserState {
    isLogin: boolean // 是否有登入
    access_token: string // 交互憑證
    data: iUserData
    logoutTimer: NodeJS.Timeout | null
}

const defaultUserData: iUserData = {
    id: 0,
    account: '',
    role: {
        id: 0,
        name: '',
        image_type: [],
        pagePermission: []
    }
};

const useUserStore = defineStore('useUserStore', () => {
    const userState = reactive<iUserState>({
        isLogin: false,
        access_token: '',
        data: { ...defaultUserData },
        logoutTimer: null
    });

    const setToken = (JWT: string) => {
        userState.access_token = JWT;

        localStorage.setItem('access_token', JWT); // 紀錄 token 在 localStorage
    };

    // 登入
    const signIn = (data: iUserData) => {
        // 記錄登入者資訊
        userState.data = data;
        localStorage.setItem('user_data', JSON.stringify(userState.data));

        userState.isLogin = true;
    };

    const signOut = () => {
        userState.data = { ...defaultUserData };
        setToken(''); // 清空token
        localStorage.setItem('user_data', '');

        userState.isLogin = false;
    };

    const getToken = () => {
        const token = userState.access_token || localStorage.getItem('access_token');

        if (token && !userState.access_token) {
            setToken(token);
        }

        return token;
    };

    const getUserData = () => {
        if (!userState.data.id) {
            const userData = localStorage.getItem('user_data');

            if (userData) {
                signIn(JSON.parse(userData));
            }
        }

        return userState.data;
    };

    const startLogoutTimer = (timer: NodeJS.Timeout) => {
        userState.logoutTimer = timer;
    };

    const clearLogoutTimer = () => {
        if (userState.logoutTimer) {
            clearTimeout(userState.logoutTimer);
            userState.logoutTimer = null;
        }
    };

    const getDomainPermissions = (domain = '') => {
        const domainTarget = userState.data.role.pagePermission.find(item => item.href === domain);

        return {
            add: domainTarget?.permissions.includes(ePermissions.Add) || false,
            edit: domainTarget?.permissions.includes(ePermissions.Edit) || false,
            delete: domainTarget?.permissions.includes(ePermissions.Delete) || false,
            view: domainTarget?.permissions.includes(ePermissions.View) || false
        };
    };

    return {
        userState,
        signIn,
        signOut,
        setToken,
        getToken,
        getUserData,
        startLogoutTimer,
        clearLogoutTimer,
        getDomainPermissions
        // isAuthenticated
    };
});

export default useUserStore;
