<script setup lang="ts">
    import piniaStore from '@/store';

    const userStore = piniaStore.useUserStore;
    const { userState } = storeToRefs(userStore);

    function filterUrl() {
        // 用正則判斷
        // 取得圖片、登入、token驗證 不觸發
        const reg = /\/api\/image\/d+|\/oauth\/token/;

        return (url: string) => {
            return !reg.test(url);
        };
    }

    const sizePrint = ref('');
    onMounted(() => {
        sizePrint.value = `${window.innerWidth} x ${window.innerHeight}`;
        window.addEventListener('resize', () => {
            sizePrint.value = `${window.innerWidth} x ${window.innerHeight}`;
        });
    });
</script>

<template>
    <div class="app" :class="{ isLogin: userState.isLogin }">
        <q-ajax-bar
            :hijack-filter="filterUrl()"
            size="5px"
        />

        <div class="sizePosition">
            {{ sizePrint }}
        </div>

        <OrgaHeader />

        <transition name="fadeY" mode="out-in" appear>
            <OrgaNav v-if="userState.isLogin" />
        </transition>

        <section class="contentBlock" :class="{ login: !userState.isLogin }">
            <router-view v-slot="{ Component }">
                <Suspense>
                    <template #default>
                        <transition name="zoom" mode="out-in">
                            <component :is="Component" />
                        </transition>
                    </template>
                    <template #fallback>
                        <ElLoadingOverlay />
                    </template>
                </Suspense>
            </router-view>
        </section>
    </div>
</template>

<style lang="scss">
    //  載入基礎樣式
    // vue 漸變樣式
    @import '@/assets/scss/vueTransition';

    // 基礎元件與行為
    @import '@/assets/scss/base';

    .app {
        @include setFlex(flex-start, center, 0, column);
        @include setSize(100%, 100%);
        @include setScroll();
        padding-left: 40px;
        padding-right: 40px;
        overflow: auto;
        &.isLogin {
            .featuresBlock {
                width: 25%;
                max-width: 300px;
            }
            .viewBlock {
                @include setSize(100%, 100%);
            }
        }

        &:not(.isLogin){
            @include setRWD(600px) {
                padding-left: 0;
                padding-right: 0;
            };
        }
        @include setRWD(1800px) {
            padding-left: 30px;
            padding-right: 30px;
        };
        @include setRWD(1024px) {
            padding-left: 20px;
            padding-right: 20px;
        };
        @include setRWD(600px) {
            padding-left: 10px;
            padding-right: 10px;
        };

        .q-loading-bar {
            background: #31726e;
        }
        .sizePosition {
            position: fixed;
            top: 0;
            right: 0;
            color: #333;
        }
    }

    .navBlock {
        @extend %scope;
        padding-left: 0;
        margin-top: 10px;
    }

    .contentBlock {
        position: relative;
        flex-grow: 1;
        background: #fff;

        // min-height: calc(100vh - 265px);
        padding-top: 40px;
        padding-bottom: 40px;
        border-radius: 35px 50px 50px;
        margin-bottom: 40px;
        @extend %scope;
        &.login {
            flex: 1;
            background: $colorBack;
            margin-bottom: 0;
        }

        .loadingMask {
            position: absolute;
            top: 0;
            left: 0;
            background: #fff;
            @include setSize(100%, 100%);
            z-index: 1;
        }
    }
</style>
